import React, { useState, useEffect } from 'react'
import Icon from '../icon/icon'
import styles from './header.module.scss'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { logout } from '@/store/user'
import { useAppDispatch } from '@/store/hooks'
interface Props{
    title?: string,
    backCallBack?: () => void,
    className?: string,
    showHeaderBack?: boolean,
    hiddenHeaderBottomLine?: boolean,
    headerColor?:string
}

const Header: React.FC<Props> = props => {
  const { channelValue, adChannelCode } = useSelector((state: RootState) => state.channel)
  const dispatch = useAppDispatch()
  let { title, backCallBack = null, className = '', showHeaderBack = true, hiddenHeaderBottomLine = false, headerColor = '' } = props
  const navigate = useNavigate()

  const back = () => {
    if (channelValue?.includes('backRegister')) {
      navigate(`/getQuota${adChannelCode ? `?adChannelCode=${adChannelCode}` : ''}`)
      dispatch(logout())
      return
    }
    if (backCallBack) {
      backCallBack()
    } else {
      navigate(-1)
    }
  }

  const getTitle = () => {
    if (title) {
      return title
    }
    switch (true) {
      case channelValue?.includes('hbjf'):
      case channelValue?.includes('mjy'):
        return '极速借款'
      case channelValue?.includes('juhaojie'):
        return '聚好借'
      case channelValue?.includes('jurongyi'):
        return '聚融易'
      case channelValue?.includes('jufu'):
        return '聚客融'
      default:
        return '有钱钱包'
    }
  }

  return (
    <header className={classNames(styles.header, className)} style={{
      borderBottom: hiddenHeaderBottomLine ? 'none' : '',
      backgroundColor: headerColor
    }}>
      { showHeaderBack && <div className={styles.back} onClick={back}>
        <Icon type="back" />
      </div> }
      <span>{ getTitle()}</span>
    </header>
  )
}

export default Header
/* eslint-disable no-magic-numbers */
import { useAppDispatch } from '@/store/hooks'
import { logout } from '../store/user'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { randomString } from '@bihu/common-js'

interface hostNameObjType {
    appName: string,
    companyName: string,
    filing:string,
    logo: string,
    appType: number,
    logoText: string
  }

// 获取域名
function useDevAPI() {
  const dispatch = useAppDispatch()
  const { channelValue, adChannelCode } = useSelector((state: RootState) => state.channel)

  // 区分环境
  const getEnvironment:() => 'local' | 'pre' | 'prod' = () => {
    if (process.env.NODE_ENV === 'development') {
      return 'local' // local表示本地环境
    } else if (process.env.REACT_APP_API_ENV === 'development') {
      return 'pre' // pre表示预发环境
    } else {
      return 'prod' // prod表示线上环境
    }
  }
  // 重走表单流程
  const reloadForm = () => {
    if (getEnvironment() === 'prod') {
      return
    }
    window.location.href = `/getQuota${adChannelCode ? `?adChannelCode=${adChannelCode}` : ''}`
    dispatch(logout())
    localStorage.clear()
  }

  // 随机生成手机号码
  const generateRandomPhoneNumber = () => {
    const first = '1' // 中国手机号以1开头
    const second = ['3', '4', '5', '6', '7', '8', '9'][Math.floor(Math.random() * 7)]
    const third = randomString(5, 'number')
    const phoneNumber = first + second + (`0000000${third}`).slice(-9)

    return phoneNumber
  }

  return {
    reloadForm,
    generateRandomPhoneNumber,
    getEnvironment
  }
}


export default useDevAPI
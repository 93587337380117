import React, { useEffect } from 'react'
import { Toast } from 'antd-mobile'
import { getParams, isIOS, isAndroid } from '@bihu/common-js'
import { useNavigate } from 'react-router-dom'
import styles from './waiting.module.scss'


interface Props{}
interface FaceResponse{
  'code': number,
  'subCode':string,
  'reason':string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  'extInfo':Record<string, any>
  }

const Empty:React.FC<Props> = () => {
  const navigate = useNavigate()

  useEffect(() => {
    let response = getParams(undefined, 'response') as string

    if (response) {
      const obj:FaceResponse = JSON.parse(response)
      let codeText:Record<number, string> = {
        1000: '刷脸成功',
        1001: '系统错误',
        1003: '验证中断',
        2002: '网络错误',
        2003: '客户端设备时间错误',
        2006: '刷脸失败,请等候',
      }


      if (obj.code === 1000) {
        Toast.show(codeText[obj.code])
        try {
          // 调用APP函数
          // eslint-disable-next-line max-depth
          if (isIOS) {
            window.webkit.messageHandlers.sendNativeMessage.postMessage(JSON.stringify({
              action: 'jump',
              data: { link: 'yuanxin://app.com/my/applyForResult' }
            }))
          } else if (isAndroid) {
            window.sendNativeMessage.postMessage(JSON.stringify({
              action: 'jump',
              data: { link: 'yuanxin://app.com/my/applyForResult' }
            }))
          }
        } catch (error) {
          navigate('/register/success')
        }
      } else {
        Toast.show(`${codeText[obj.code]}，请重试`)
        navigate('/fill-information')
      }
    } else {
      Toast.show('人脸识别未返回参数')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section className={styles.waiting}>
      等待人脸识别结果中...
    </section>
  )
}

export default Empty
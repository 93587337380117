/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-magic-numbers */
import React, { useEffect, useState, useRef } from 'react'
import { Button, Toast, Popup } from 'antd-mobile'
import styles from './authorize-commom-popup.module.scss'
import classNames from 'classnames'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { isIOS, isAndroid, isMobile, Storage } from '@bihu/common-js'
import { ENTER_AGREEMENT, TOKEN } from '@/constants/storage'
import indexApi from '@/apis'
import useFirstOrAfter from '@/hooks/use-first-or-after'
import ApiToH5Skin from './components/api-to-h5-skin/api-to-h5-skin-popup'
import ProtocolIdsConfirm2 from '@/pages/fill-information/components/protocolIds-confirm-2/protocolIds-confirm-2'
import { selectUserInfo } from '@/store/user'
import { useAppSelector } from '@/store/hooks'
import InstitutionList from '../institution-list/institution-list'
import { RightOutline } from 'antd-mobile-icons'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import sensors from 'sa-sdk-javascript'
import { SensorsType } from '@/constants/sensorsBurying'

interface Props{
  matchingResult:any
}

interface ProtocolType {
  id: string,
  name: string | null,
  protocolType:number | null
}

interface InstitutionProduct {
  id: number,
  productCompany: string | null,
  productName: string | null,
  partnerProtocolList:ProtocolType[] | null
}


const Authorize: React.FC<Props> = ({ matchingResult }) => {
  const { channelValue, adChannelCode } = useSelector((state: RootState) => state.channel)
  const ProtocolIdsConfirm1ModalRef: any = useRef()
  const ProtocolIdsConfirm2ModalRef: any = useRef()
  const [search] = useSearchParams()
  const userInfo = useAppSelector(selectUserInfo)

  const [details, setDetails] = useState<any>({})
  const [rqResult, setRqResult] = useState<any>()
  const [otherProtocols, setotherProtocols] = useState([])
  const [youqianlaiProtocol, setYouqianlaiProtocol] = useState<any>('')
  const [agreementContent, setAgreementContent] = useState('')
  // 如果是匹配多产品，则显示匹配的多产品协议列表
  const [institutionProtocol, setInstitutionProtocol] = useState<any>([])
  // 多产品树状协议列表下，当前点击的树状节点
  const [checkProtocol, setCheckProtocol] = useState<string>('')
  // 当前勾选的多产品
  const [checkInstitution, setCheckInstitution] = useState<number[]>([])

  const navigate = useNavigate()

  // 前端神策埋点 - 访问授权页
  const sensorsVisitAuthorizePage = (type: SensorsType.visit_authorization_page | SensorsType.click_authorize, checkedProduct?: number[]) => {
    try {
      const tempDetails: any = matchingResult
      let productList = []
      let selectProductList = []
      if (tempDetails.rqResult?.thirdpartyType === 7) {
        productList.push(`${tempDetails.rqResult?.productName}-${tempDetails.rqResult.yqlWholeProcessBumpVO?.partnerProductName}`)
        selectProductList.push(`${tempDetails.rqResult?.productName}-${tempDetails.rqResult.yqlWholeProcessBumpVO?.partnerProductName}`)
      } else {
        productList.push(tempDetails.rqResult?.productName)
        selectProductList.push(tempDetails.rqResult?.productName)
      }
      if (tempDetails.rqResult?.mayAuthProductList?.length > 0) {
        tempDetails.rqResult?.mayAuthProductList.forEach((element:any) => {
          if (element.thirdpartyType === 7) {
            productList.push(`${element.productName}-${element.partnerProductName}`)
            if (checkedProduct && checkedProduct.includes(element.id)) {
              selectProductList.push(`${element.productName}-${element.partnerProductName}`)
            }
          } else {
            productList.push(element.productName)
            if (checkedProduct && checkedProduct.includes(element.id)) {
              selectProductList.push(element.productName)
            }
          }
        })
      }
      let updateInfo:any = {
        ad_channel_code: adChannelCode || search.get('adChannelCode') || localStorage.getItem('ad_channel_code'),
        mobile: userInfo?.mobile || localStorage.getItem('user_phone'),
        h5_matched_product_list: productList.join(';'),
        h5_matched_product_count: productList.length
      }
      if (type === SensorsType.visit_authorization_page) {
        sensors.track(type, updateInfo)
      } else {
        updateInfo.h5_matched_product_selected_list = selectProductList.join(';')
        sensors.track(type, updateInfo)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useFirstOrAfter(() => {
    localStorage.removeItem('productBack')
    if (!Storage.get('isRefreshLoadPage') && !search.get('refresh')) {
      Storage.set('isRefreshLoadPage', true)
      // window.location.href = `${document.URL}&refresh=1`
      return
    } else {
      Storage.set('isRefreshLoadPage', false)
    }

    // 监听浏览器回退事件
    window.addEventListener('popstate', back, false)
    // window.onpopstate = back
    window.history.pushState(null, 'null', document.URL)

    return () => {
      // 回退事件只用于当前组件，则需要在组件销毁时把回退事件销毁
      window.removeEventListener('popstate', back, false)
      // window.onpopstate = null
    }
  }, () => {
  // eslint-disable-next-line no-console
  }, [])

  // 打开挽留弹窗
  const openProtocolIdsConfirm1Modal = () => {
    let tempYouqianlaiProtocol = ''
    let temPotherProtocols:any = []
    // eslint-disable-next-line array-callback-return
    rqResult.protocols.map((item:any) => {
      if (item.protocolType !== 1) {
        temPotherProtocols.push(item)
      } else if (item.protocolType === 1) {
        tempYouqianlaiProtocol = item
      }
    })
    ProtocolIdsConfirm1ModalRef.current.init({
      quota: details.applyLimit >= 3 ? '100,000' : '50,000',
      productName: rqResult.productName,
      youqianlaiProtocol: tempYouqianlaiProtocol,
      otherProtocols: temPotherProtocols,
      productId: rqResult.id,
    })
  }

  // 返回
  const back = () => {
    const isShowPopupStorage = Storage.get('isShowPopup')

    try {
      if (isShowPopupStorage) {
        Storage.set('isShowPopup', false)
        navigate(-1)
      } else {
        //  阻止浏览器回退事件
        openProtocolIdsConfirm1Modal()
        Storage.set('isRefreshLoadPage', false)
        Storage.set('isShowPopup', true)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      // console.log(error)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  const [token, setToken] = useState('')
  const [apiChannelCode, setApiChannelCode] = useState('')
  const [serialNo, setSerialNo] = useState('')
  const [isSpecialChannel, setIsSpecialChannel] = useState<boolean>(false)
  const [jumpUrl, setJumpUrl] = useState('')
  // 当前操作第几回授权
  const [authNum, setAuthNum] = useState(0)
  let urlRqResult:any = search.get('rqResult')
  useEffect(() => {
    let urlApiChannelCode:any = search.get('apiChannelCode')
    let urlSerialNo:any = search.get('serialNo')
    let urlToken:any = search.get('token')
    let urlJumpUrl:any = search.get('jumpUrl')
    //设置authNum
    let urlAuthNum: any = search.get('authNum')

    if (urlAuthNum) {
      setAuthNum(Number(urlAuthNum))
    }
    if (urlApiChannelCode) {
      setSerialNo(urlSerialNo)
      setApiChannelCode(urlApiChannelCode)
      localStorage.setItem('ad_channel_code', urlApiChannelCode)
      if (urlJumpUrl) {
        const tempJumpUrl = JSON.parse(decodeURIComponent(urlJumpUrl))
        setJumpUrl(tempJumpUrl)
      }
      if (urlApiChannelCode === 'kxj01' || urlApiChannelCode === 'kxj02') {
        setIsSpecialChannel(true)
      }
      if (urlToken) {
        Storage.set(TOKEN, urlToken)
        setToken(urlToken)
      }
    } else {
      let checkProduct
      // 如果是多产品分，那么给产品默认添加勾选
      if (matchingResult.rqResult?.mayAuthProductList?.length > 0) {
        checkProduct = matchingResult.rqResult.mayAuthProductList.map((product: InstitutionProduct) => {
          return product.id
        })
        setCheckInstitution(checkProduct)
      }
      setDetails(matchingResult)
      setRqResult(matchingResult.rqResult)
    }
    // 前端神策埋点 - 用户访问授权页
    sensorsVisitAuthorizePage(SensorsType.visit_authorization_page)
  }, [])

  // 应用在API采量授权页重刷的场景
  const reVisitApiAuthPage = () => {
    if (urlRqResult) {
      const tempRqResult = JSON.parse(decodeURIComponent(urlRqResult))
      setRqResult(tempRqResult)
      setDetails({
        applyLimit: tempRqResult.applyLimit
      })
    }
  }

  useEffect(() => {
    let newProtocols: any = []
    let ProtocolTreeList:any = []
    // eslint-disable-next-line array-callback-return
    rqResult && rqResult?.protocols?.map((item:any, index:number) => {
      if (item.protocolType !== 1) {
        newProtocols.push(item)
        ProtocolTreeList.push(item)
      } else if (item.protocolType === 1) {
        setYouqianlaiProtocol(item)
      }
    })

    // 针对有钱来全流程类型的合作方式，需要把第三方协议并入合作机构协议中展示
    if (rqResult && rqResult.thirdpartyType === 7) {
      if (rqResult.yqlWholeProcessBumpVO && rqResult.yqlWholeProcessBumpVO.partnerProtocolList) {
        newProtocols = [
          ...newProtocols,
          ...rqResult.yqlWholeProcessBumpVO.partnerProtocolList
        ]
        ProtocolTreeList.push({
          id: Math.random().toString(36),
          name: rqResult.yqlWholeProcessBumpVO?.partnerCompanyName || '合作机构协议',
          protocolList: rqResult.yqlWholeProcessBumpVO.partnerProtocolList,
        })
      }
    }
    // 如果是多产品，那么就将展示多产品形式的树状协议列表
    if (rqResult && rqResult?.mayAuthProductList?.length > 0) {
      rqResult?.mayAuthProductList.forEach((product: InstitutionProduct) => {
        if (!product.partnerProtocolList?.length) {
          return
        }
        ProtocolTreeList.push({
          id: product.id,
          name: product.productName,
          protocolList: product.partnerProtocolList
        })
      })
    }
    setInstitutionProtocol(ProtocolTreeList)
    setotherProtocols(newProtocols)
  }, [rqResult])

  useEffect(() => {
    async function getAgreementInfo() {
      const res:any = await indexApi.getAgreementInfo(youqianlaiProtocol.id, rqResult.id)
      setAgreementContent(res.content)
    }

    if (youqianlaiProtocol) {
      getAgreementInfo()
    }

  }, [youqianlaiProtocol])

  useEffect(() => {
    async function getFomrSubmitStatus() {
      const res:any = await indexApi.getFomrSubmitStatus({
        adChannelCode: apiChannelCode,
        serialNo
      })
      // 如果用户已经授权过，并且第二回授权没有结果，跳转到上回结果页
      if (res.submitStatus === 10 && res.rematchResult !== 3) {
        const reMatchResult:any = res
        let tempOptions:any = {}
        // 否则取上回的结果，跳至下载页
        tempOptions = JSON.parse(JSON.stringify(reMatchResult))
        tempOptions.isNotFormReq = true // 标记为非表单请求方式的跳转
        tempOptions.rePageShowType = 0
        tempOptions.revisit = true // 标记为再访
        navigate(`/fill-information/success?options=${encodeURIComponent(JSON.stringify(tempOptions))}`)
        return
      }
      // 判断是否匹配回调类型(如：惠逸花、惠逸花A)
      // if (res.thirdpartyType === 5) {
      //   toHuiYiHuaPage(res)
      //   return
      // }

      setDetails({
        applyLimit: res.applyLimit
      })
      setRqResult(res)
    }

    // 说明当前路由已被修改，从URl中获取修改后的数据
    if (apiChannelCode && authNum > 0) {
      reVisitApiAuthPage()
    } else if (token && apiChannelCode) {
      getFomrSubmitStatus()
      let devicePlatForm:any = 3
      if (isAndroid) {
        devicePlatForm = 1
      }
      if (isIOS) {
        devicePlatForm = 2
      }
      indexApi.reportPhoneType(devicePlatForm)
    }

  }, [token, apiChannelCode, serialNo])


  useEffect(() => {
    const code = search.get('adChannelCode')
    if (code) {
      localStorage.setItem('ad_channel_code', code)
    }
  }, [])

  // 选择协议
  const [checked, setChecked] = useState(true)

  // 切换选中协议
  const checkedTaggle = () => {
    setChecked(!checked)
  }

  // 按钮loading
  const [btnLoading, setBtnLoading] = useState(false)

  // 跳转页面
  const openOtherView = (url: string) => {
    Storage.set(ENTER_AGREEMENT, true)
    navigate(url)
  }

  const submitApplication = async(isNeedChecked = true) => {
    try {
      // 如果需要勾选但是没有勾选并且不联登就打开勾选协议弹层
      if (!checked && isNeedChecked && matchingResult?.rqResult?.unionLoginFlag !== 0) {
        ProtocolIdsConfirm2ModalRef.current.init({
          youqianlaiProtocol,
          otherProtocols,
          productId: rqResult.id,
        })
        return
      }
      let protocolIds = rqResult.protocols.map((item:any) => {
        return item.id
      })

      // 如果匹配到的是H5产品，则走H5产品的申请、联登流程
      if (rqResult.thirdpartyType === 1) {
        handleH5Product(rqResult.id, 0, protocolIds)
        return
      }

      //获取在登录页时保存的百度渠道的全链接
      const bdVidUrl = localStorage.getItem('baiduVistLink') || ''
      setBtnLoading(true)
      // 前端日志上报
      const reportParam = `uid:${userInfo?.uid},adChannelCode:${details.adChannelCode || apiChannelCode},event:授权点击`
      indexApi.reportLog(reportParam)
      // 百度埋点-授权
      if (channelValue?.includes('bdVid')) {
        window._agl && window._agl.push(['track', ['success', { t: 5 }]])
      }
      // 前端神策埋点 - 用户点击授权
      sensorsVisitAuthorizePage(SensorsType.click_authorize, checkInstitution)
      // 信息授权
      const res:any = await indexApi.confirmAuthorize({
        productId: rqResult.id,
        protocolIds,
        agree: true,
        adChannelCode: details.adChannelCode,
        isHuaWeiPhone: details.isHuaWeiPhone,
        apiChannelCode,
        orderNum: authNum, //第几回授权
        serialNo: rqResult.serialNo || serialNo,
        productIds: checkInstitution
      })
      setBtnLoading(false)
      handleAduitSuccess(res)
    } catch (error) {
      setBtnLoading(false)
    }
  }

  // 处理H5产品逻辑
  const handleH5Product = async(productId:any, entranceType:number, protocolIds:any) => {
    // 申请产品
    let applyProductRes:any = await indexApi.applyFor({
      productId,
      entranceType,
      appH5: 2
    })
    // 判断是否需要联登
    if (applyProductRes?.unionLoginFlag === 1) {
      try {
        const authH5ProductRes:any = await indexApi.authH5Product({
          productId,
          protocolIds: `${protocolIds}${''}`,
          agree: true,
          entranceType,
          appH5: 2
        })
        if (authH5ProductRes) {
          window.location.href = authH5ProductRes
        } else {
          navigate(`/fill-information/success?options=${encodeURIComponent(JSON.stringify({ rePageShowType: details.adChannelCode === '9o0w2B' && details.isHuaWeiPhone ? 2 : 0 }))}`)
        }
      } catch (error) {
        navigate(`/fill-information/success?options=${encodeURIComponent(JSON.stringify({ rePageShowType: details.adChannelCode === '9o0w2B' && details.isHuaWeiPhone ? 2 : 0 }))}`)
      }
    } else {
      window.location.href = applyProductRes.thirdpartyTargetUrl
    }
  }

  // 修改页面url
  const reFreshPageUrl = (newRqResult:any, tempAuthNum: any) => {
    const pageUrl = window.location.origin + window.location.pathname
    let urlOptions = ''
    // 如果是API采量授权方式
    if (apiChannelCode) {
      let modifiedUrl = `${pageUrl}?apiChannelCode=${apiChannelCode}&jumpUrl=${encodeURIComponent(JSON.stringify(jumpUrl))}&authNum=${tempAuthNum}&rqResult=${encodeURIComponent(JSON.stringify(newRqResult))}&serialNo=${serialNo}`
      window.history.replaceState(null, 'null', modifiedUrl)
    } else {
      urlOptions = {
        ...details,
        rqResult: newRqResult,
      }

      window.history.replaceState(null, 'null', `${pageUrl}?options=${encodeURIComponent(JSON.stringify(urlOptions))}&authNum=${tempAuthNum}`)
    }
  }

  const handleAduitSuccess = async(res:any) => {
    // 正常授权且有结果
    if (res && res.applyStatus !== 2) {
      // 如果url上有携带跳转地址jumpUrl，则跳转
      if (jumpUrl) {
        res.jumpUrl = jumpUrl
        navigate(`/fill-information/success-transfer?options=${encodeURIComponent(JSON.stringify(res))}`)
        return
      }
      // 如果是API转H5类型，则直接跳转第三方链接
      if (res.thirdpartyType === 4 && res.thirdpartyTargetUrl) {
        await indexApi.apiH5JumpUrlSuccessFlag({
          uid: userInfo?.uid,
          productId: rqResult.id,
          productName: rqResult.productName,
          thirdpartyTargetUrl: res.thirdpartyTargetUrl
        })
        window.location.href = res.thirdpartyTargetUrl
        return
      }

      // 如果授权失败，但是有返回下一个产品，就重新授权
      if (res.applyStatus === 4 && res.productNextMatchDTO) {
        // productNextMatchDTO没有返回serialNo，需要保存
        if (rqResult.serialNo) {
          res.productNextMatchDTO.serialNo = rqResult.serialNo
        }
        // 判断是否匹配回调类型(如：惠逸花、惠逸花A)
        // if (res.productNextMatchDTO.thirdpartyType === 5) {
        //   toHuiYiHuaPage(res.productNextMatchDTO)
        //   return
        // }
        // 如果是其它产品，则更新本页面信息
        setRqResult(res.productNextMatchDTO)
        setAuthNum(res.productNextMatchDTO.orderNum)
        Toast.show('申请名额已满，您的匹配机构已更新，请继续操作')
        reFreshPageUrl(res.productNextMatchDTO, res.productNextMatchDTO.orderNum)
        return
      }

      navigate(`/fill-information/success?options=${encodeURIComponent(JSON.stringify(res))}&isMultiProduct=${checkInstitution.length > 0}`)
      return
    }

    // 结果页
    // 走这里说明是重复授权或者授权失败，重复授权也需要判断res
    const options:any = res ? res : { rePageShowType: details.adChannelCode === '9o0w2B' && details.isHuaWeiPhone ? 2 : 0 }
    navigate(`/fill-information/success?options=${encodeURIComponent(JSON.stringify(options))}&isMultiProduct=${checkInstitution.length > 0}`)
  }
  const handleLoginSuccess = (res:any) => {
    //
  }
  const handelSubmitData = () => {
    setChecked(true)
    submitApplication(false)
  }

  // 弹起协议相关列表
  const openProtocolsPopup = () => {
    setProtocolIdsVisible(true)
  }

  // 是否显示弹出层
  const [protocolIdsVisible, setProtocolIdsVisible] = useState(false)

  // 是否显示弹出层
  const [monthVisible, setMonthVisible] = useState(false)
  // 当前选中月份
  // eslint-disable-next-line no-magic-numbers
  const [currentMonth, setCurrentMonth] = useState<number>(12)

  // 月份数组
  const monthArr = [
    {
      key: 1,
      num: 3,
    },
    {
      key: 2,
      num: 6,
    },
    {
      key: 3,
      num: 9,
    },
    {
      key: 4,
      num: 12,
    },
  ]

  // 选择月份
  const selectMonth = (num: number) => {
    setCurrentMonth(num)
    setMonthVisible(false)
  }

  // 倒计时
  const [countdownTime, setCountdownTime] = useState<number>(5)
  const timer = useRef<ReturnType<typeof setTimeout>>()

  // 倒计时
  useEffect(() => {
    if (isMobile) {
      if (countdownTime > 0) {
        timer.current = setTimeout(() => {
          setCountdownTime(countdownTime - 1)
        }, 1000)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdownTime])

  const checkOtherProtocols = (item: any) => {
    if (item.id) {
      openOtherView(`/info-audit-agreement?isShowHeader=true&agreementId=${item.id}&title=${item.name}&productId=${rqResult.id}`)
    } else if (item.url) {
      window.location.href = item.url
    }
  }
  // 点击协议树状结构
  const checkFaProtocol = (protocol: any) => {
    if (protocol.protocolList?.length > 0) {
      if (protocol.name === checkProtocol) {
        setCheckProtocol('')
      } else {
        setCheckProtocol(protocol.name)
      }
    } else {
      checkOtherProtocols(protocol)
    }
  }

  // 当多产品分发的产品勾选改变
  const onCheckedChange = (id: number, value: boolean) => {
    // 创建状态的副本
    let newCheckInstitution = [...checkInstitution]
    const index = newCheckInstitution.findIndex(item => item === id)

    if (value) {
      // 如果 value 为 true 且 id 不在数组中，则将 id 添加到数组中
      newCheckInstitution.push(id)
    } else {
      // 如果 value 为 false 且 id 在数组中，则将 id 从数组中移除
      newCheckInstitution.splice(index, 1)
    }

    // 更新状态
    setCheckInstitution(newCheckInstitution)
  }

  return (
    <section className={styles.authorizePage}>
      {/* <Header title="有钱来" /> */}
      <div>
        {
          rqResult?.thirdpartyType && rqResult?.thirdpartyType !== 5
            ? <>
              {
                apiChannelCode ? <>
                  <ApiToH5Skin
                    rqResult={rqResult ? rqResult : {}}
                    agreementContent={agreementContent}
                    youqianlaiProtocolName={youqianlaiProtocol?.name}
                    isSpecialChannel={isSpecialChannel}
                  />
                </> : <>
                  {
                    rqResult?.thirdpartyType
                      && <>
                        <InstitutionList rqResult={rqResult} onCheckedChange={onCheckedChange} checkInstitution={checkInstitution} isPopupType={true}></InstitutionList>
                        {
                          // 判断是否需要联登，如果不需要联登的话就不展示协议
                          matchingResult?.rqResult?.unionLoginFlag !== 0 ? <div className={styles.protocolBox}>
                            <div className={styles.specialTips}>
                        特别提示：我们会根据您的资质，为您匹配专业的贷款咨询服务机构，请您查阅《个人信息共享授权协议》
                            </div>
                            <div className={styles.protocolBoxContent}>
                              <div className={styles.protocolBoxTitle}>{ youqianlaiProtocol.name }</div>
                              <div dangerouslySetInnerHTML={{ __html: agreementContent }}></div>
                            </div>
                          </div>
                            : <div className={styles.protocolBox}>
                              <div className={styles.flowBox}>
                                <div className={styles.titleBox}>
                                  <img className={styles.titleLine} src={require('@imgs/ios-skin/title-line-left.png')} alt="title-line-left" />
                                  <div className={styles.titleText}>正规产品 安全信赖</div>
                                  <img className={styles.titleLine} src={require('@imgs/ios-skin/title-line-right.png')} alt="title-line-left" />
                                </div>
                              </div>
                              <div className={styles.stepBox}>
                                <div className={styles.stepItem}>
                                  <img className={styles.stepItemIcon} src={require('@imgs/yqzs/step-ic-1.png')} />
                                  <div className={styles.stepItemName}>01 领取额度</div>
                                </div>
                                <img className={styles.dashedline1} src={require('@imgs/yqzs/dashedline.png')} alt="jingxuan" />
                                <div className={styles.stepItem}>
                                  <img className={styles.stepItemIcon} src={require('@imgs/yqzs/step-ic-2.png')} />
                                  <div className={styles.stepItemName}>02 申请借款</div>
                                </div>
                                <img className={styles.dashedline2} src={require('@imgs/yqzs/dashedline.png')} alt="jingxuan" />
                                <div className={styles.stepItem}>
                                  <img className={styles.stepItemIcon} src={require('@imgs/yqzs/step-ic-3.png')} />
                                  <div className={styles.stepItemName}>03 机构放款</div>
                                </div>
                              </div>
                            </div>
                        }
                        {
                          matchingResult?.rqResult?.unionLoginFlag === 0 && <div className={styles.unionLogText}>
                          郑重声明:平台只提供贷款咨询和推荐服务，放款由机构进行，所有贷款申请在未成功贷款前绝不收取任何费用，为了保证您的资全安全，请不要相信任何要求您支付费用的信息、邮件电话等不实信息贷款额度，利率，放款时间以实际审批结果为准。
                          </div>
                        }
                      </>
                  }
                </>
              }
              <div className={styles.footerBox}>
                {
                  matchingResult?.rqResult?.unionLoginFlag !== 0
                    ? <div className={styles.footerText}>
                      {
                        checked ? <>
                          <img onClick={checkedTaggle} className={styles.checkedIcon} src={require('@imgs/information/checked.png')} />
                        </> : <>
                          <div className={classNames(styles.checked)} onClick={checkedTaggle}></div>
                        </>
                      }
                      <div>
                        <span onClick={checkedTaggle}>同意并签署</span>
                        <span
                          className={styles.footerTextRed}
                          onClick={() => {
                            openOtherView(`/info-audit-agreement?isShowHeader=true&agreementId=${youqianlaiProtocol.id}&title=${youqianlaiProtocol.name}&productId=${rqResult.id}`)
                          }}
                        >
                《{youqianlaiProtocol.name}》
                        </span>
                        {
                          otherProtocols.length > 0 && <span
                            className={styles.footerTextRed}
                            onClick={() => {
                              openProtocolsPopup()
                            }}
                          >
                    《机构相关协议》
                          </span>
                        }
                      </div>
                    </div> : <></>
                }
                <div className={styles.btnBox}>
                  <Button block loading={btnLoading} loadingText="请等待机构申请结果" className={styles.btn} shape="rounded" color="primary" onClick={() => submitApplication(true)}>
                    { `立即领取额度${countdownTime ? `（${countdownTime}s）` : ''}`}
                  </Button>
                </div>
                <div className={styles.paddingBox}></div>
              </div>
            </> : <>
              <div id="root">
                <div id="loading-box">
                  <div className={styles.ballClipRotateMultiple}>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
            </>
        }
      </div>


      {/* <Popup
        visible={monthVisible}
        onMaskClick={() => {
          setMonthVisible(false)
        }}
      >
        <ul className={styles.monthBox}>
          {
            monthArr.map(item => {
              return <li
                key={item.key}
                className={classNames(styles.row, item.num === currentMonth && styles.active)}
                onClick={() => selectMonth(item.num)}
              >
                {item.num} 个月
              </li>
            })
          }
        </ul>
      </Popup> */}

      <Popup
        visible={protocolIdsVisible}
        showCloseButton={true}
        bodyStyle={{
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          minHeight: '40vh',
        }}
        onMaskClick={() => {
          setProtocolIdsVisible(false)
        }}
        onClose={() => {
          setProtocolIdsVisible(false)
        }}
      >
        <div className={styles.protcolPopup}>
          <div className={styles.popupTitle}>协议列表</div>
          <div className={styles.protocolIdsBox}>
            {
              rqResult?.mayAuthProductList?.length > 0
              // 如果是多产品分发，则显示树状协议列表
                ? institutionProtocol.map((protocol: any) => {
                  return <div key={protocol.name}>
                    <div
                      className={styles.protocolIdItem}
                      onClick={() => checkFaProtocol(protocol)}
                    >
                      <div>{ `《${protocol.name}》` }</div>
                      <img className={classNames([styles.arrowBlack, checkProtocol === protocol.name && styles.chooseArrow])} src={require('@imgs/information/arrow-black.png')} />
                    </div>
                    {
                      checkProtocol === protocol.name ? protocol?.protocolList.map((child:any) => {
                        return <div key={child.name} onClick={() => checkOtherProtocols(child)} className={styles.protocolChild}>
                          <span>{child.name}</span>
                          <RightOutline />
                        </div>
                      }) : <></>
                    }
                  </div>
                })
                : otherProtocols.map((item: any) => {
                  return (
                    <div
                      className={styles.protocolIdItem}
                      onClick={() => checkOtherProtocols(item)}
                      key={item.name}
                    >
                      <div>{ `${item.name}` }</div>
                      <img className={styles.arrowBlack} src={require('@imgs/information/arrow-black.png')} />
                    </div>
                  )
                })
            }
          </div>
        </div>
      </Popup>
      {/* 同意协议弹窗 */}
      <ProtocolIdsConfirm2
        ref={ProtocolIdsConfirm2ModalRef}
        rqResult={rqResult}
        onSuccess={(e:any) => handleLoginSuccess(e)}
        openProtocolsPopup={() => openProtocolsPopup()}
        submit={() => handelSubmitData()}/>
    </section>
  )
}

export default Authorize
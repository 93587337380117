/* eslint-disable camelcase */
/* eslint-disable max-lines */
/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-console */
/* eslint-disable no-magic-numbers */
import React, { useEffect, useState, useRef, useMemo } from 'react'
import Header from '@/components/header/header'
import styles from './new-fill-information.module.scss'
import CountUp from 'react-countup'
import { isIOS, isAndroid, validateIDNumber, Storage, parseNum, debounce } from '@bihu/common-js'
import { OptionsType } from './components/submit-waiting-popup/submit-waiting-popup'
import { Button, Toast, Popup } from 'antd-mobile'
import type { PickerValue, PickerValueExtend } from 'antd-mobile/es/components/picker'
import cityDate from '@/assets/js/city'
import indexApi from '@/apis'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { OptionType } from '@/pages/fill-information/options'
import { TOKEN } from '@/constants/storage'
import {
  formItems // 申请表单
} from './options'
import { getSex, getAge } from '@/utils/utils'
import activityApi from '@/apis/activity'
import UserInfoFormComponent, { UserInfoFormComponentHandles } from './components/user-info-form/user-info-form'
import AuthorizeCommonPopup from './components/authorize-commom-popup/authorize-commom-popup'
import { removeToken } from '@/utils/user'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import sensors from 'sa-sdk-javascript'
import { useAppSelector } from '@/store/hooks'
import { selectUserInfo } from '@/store/user'
import { SensorsType } from '@/constants/sensorsBurying'

// 1.首先判断是非支持scrollTo，通过document.documentElement.style上是否有 scroll-behavior 这个属性。
// 2.对element添加scrollTo的方法。在原型对象上添加方法scrollTo
if (!('scrollBehavior' in document.documentElement.style)) {
  const Element = window.HTMLElement || window.Element

  Element.prototype.scrollTo = function() {
    let left = 0
    let top = 0
    if (arguments.length > 1) {
      left = arguments[0]
      top = arguments[1]
    } else {
      left = arguments[0].left
      top = arguments[0].top
    }
    this.scrollLeft = left
    this.scrollTop = top
  }
}

export interface FormItem {
  name: string,
  key: string,
  value: string,
  options:Array<OptionType>
}
// 提交匹配参数
export interface MatchParams {
  realName?:string
  age?:string | number
  gender?:string
  cityShow?:string
  sesameCredit?:string
  creditInvestigation?:string
  applyLimit?:string
  houseProperty?:string
  carProperty?:string
  socialSecurity?:string
  housingFund?:string
  insurance?:string
  job?:string
  workDuration?:string
  monthlyProfit?:string
  payoffForm?: string
  identity?: string
  workCityCode?: string
  workCity?: string
}
// 表单提交参数
export interface SubmitParams extends MatchParams {
  adChannelCode: string
  isHuaWeiPhone: boolean
}

type SetFormFunc = (obj: OptionType, currentItem: FormItem) => void

interface Props{}

// 元信大额，填写资料
const FillInformation:React.FC<Props> = () => {
  const { channelValue, adChannelCode } = useSelector((state: RootState) => state.channel)
  // 用户信息
  const userInfo = useAppSelector(selectUserInfo)
  const navigate = useNavigate()
  const [search] = useSearchParams()
  const [visibleHead, setVisibleHead] = useState(true)

  const [progressPercentage, setProgressPercentage] = useState(50)
  const [estimatedAmount, setEstimatedAmount] = useState(50000)
  // 上次额度显示(数字滚动动画需要的属性)
  const [lastTimeAmount, setLastTimeAmount] = useState(0)

  const [isFirstSubmit, setIsFirstSubmit] = useState(true)

  const [token, setToken] = useState('')

  const [isHuaWeiPhone, setIsHuaWeiPhone] = useState<boolean>(false)

  const [formType, setFormType] = useState<'form' | 'popup'>('form')
  const UserInfoFormRef = useRef<UserInfoFormComponentHandles | null>(null)
  const popupContentRef = useRef<HTMLDivElement | null>(null)
  const [isSubimtWaiting, setIsSubimtWaiting] = useState<number>(0) // 控制是否开启匹配 0是未开始匹配 1是打开匹配 2是匹配完成 3是匹配完成并且已经打开了匹配结果页面
  const [waitingProgress, setWaitingProgress] = useState(0) // 匹配进度
  const [isOpenAuthorizePopup, setIsOpenAuthorizePopup] = useState(false) //控制是否打开匹配结果弹层
  const [matchingResult, setmatchingResult] = useState<OptionsType>() // 授权跳转页数据
  const [isFirstOut, setisFirstOut] = useState(true)

  useEffect(() => {
    //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
    let ua = window.navigator.userAgent.toLowerCase()

    //通过正则表达式匹配ua中是否含有MicroMessenger字符串
    if (/MicroMessenger/i.test(ua) || !visibleHead) {
      document.title = '填写资料'
      setVisibleHead(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isHuawei = () => {
    const userAgent = navigator.userAgent.toLowerCase()
    if (userAgent.indexOf('huawei') !== -1) {
      setIsHuaWeiPhone(true)
      return
    }
    setIsHuaWeiPhone(false)
  }

  useEffect(() => {
    isHuawei()
  }, [])


  // 步骤,当前选择到哪一步
  const [step, setStep] = useState(1)

  const [formDetail, setFormDetail] = useState<any>('')

  useEffect(() => {
    async function checkMobileLogin(reqId:string) {
      const res = await indexApi.checkMobileLogin({ reqId })
      // 如果以前注册过了有钱来，直接登录拿token
      if (res) {
        const loginRes = await activityApi.login({
          upStreamUnionLoginReqId: reqId,
        })
        handleLoginSuccess(loginRes)
      }
    }
    const urlToken = search.get('token')
    const reqId = search.get('reqId')


    let tokenTemp = Storage.get(TOKEN)
    if (reqId) {
      // 处理OPPO联登逻辑
      checkMobileLogin(reqId)
    } else if (urlToken) {
      Storage.set(TOKEN, urlToken)
      setToken(urlToken)
      const url = window.location.href
      const updatedUrl = url.replace(/([?&])token=[^&]+(&|$)/, '$1')
      window.history.replaceState(null, 'null', updatedUrl)
    } else if (tokenTemp) {
      setToken(tokenTemp)
    }

    // 与app交互的函数
    window.getToken = function(t:string) {
      Storage.set(TOKEN, t)
      setToken(t)
    }
    try {
      if (isIOS) {
        window.webkit.messageHandlers.sendNativeMessage.postMessage(JSON.stringify({
          action: 'getToken',
          data: {
            methodName: 'getToken'
          }
        }))
      } else if (isAndroid) {
        window.sendNativeMessage.postMessage(JSON.stringify({
          action: 'getToken',
          data: {
            methodName: 'getToken'
          }
        }))
      }
    } catch (error) {
      // console.log(error)
    }
  }, [])

  useEffect(() => {
    if (formDetail) {
      fillData(formDetail)
    }
  }, [formDetail])

  // 获取用户资料
  useEffect(() => {
    async function getApplyStatus() {
      try {
        const res:any = await indexApi.getApplyStatus()
        indexApi.reportUserBehavior(1)
        let devicePlatForm:any = 3
        if (isAndroid) {
          devicePlatForm = 1
        }
        if (isIOS) {
          devicePlatForm = 2
        }
        indexApi.reportPhoneType(devicePlatForm)
        setFormDetail(res.data)

        // 获取表单填写状态  submitStatus >>> 0:未提交 1:已提交未授权 10:已授权
        if (res.data && res.data?.submitStatus === 1) {
          // 获取上回提交信息
          const submitResult:any = await indexApi.getFomrSubmitStatus({ adChannelCode })
          // 匹配产品失败时，会之间跳转success结果页，但是没有匹配产品
          if (submitResult.productName === null) {
            navigate(`/fill-information/success?options=${encodeURIComponent(JSON.stringify({
              isNotFormReq: true,
              rePageShowType: channelValue?.includes('fenqile') && isHuaWeiPhone ? 2 : 0,
              revisit: true
            }))}`)
            return
          }
          // 跳转授权页
          let tempOptions = {
            adChannelCode,
            isHuaWeiPhone,
            applyLimit: res.data?.applyLimit,
            workCity: res.data?.workCity,
            rqResult: submitResult
          }
          setmatchingResult(tempOptions)
          setIsOpenAuthorizePopup(true)
        } else if (res.data && res.data?.submitStatus === 10) {
          // rematchResult: 1-不需要重新匹配 2-重新匹配不到结果 3-重新匹配到结果
          const options = search.get('options')
          const reMatchResult: any = options ? JSON.parse(decodeURIComponent(options)) : null

          let tempOptions:any = {}
          // 如果重现匹配并且有结果，跳至动图页
          if (reMatchResult) {
            // 携带授权数据跳转重新匹配结果页
            setmatchingResult(reMatchResult)
            setIsOpenAuthorizePopup(true)
            return
          }

          // 否则取上回的结果，跳至下载页
          tempOptions = JSON.parse(JSON.stringify(reMatchResult))
          tempOptions.isNotFormReq = true // 标记为非表单请求方式的跳转
          tempOptions.rePageShowType = channelValue?.includes('fenqile') && isHuaWeiPhone ? 2 : 0
          tempOptions.revisit = true // 标记为再访
          navigate(`/fill-information/success?options=${encodeURIComponent(JSON.stringify(tempOptions))}`)
        }
      } catch (error) {
        console.log('error', error)
      }
    }
    if (token) {
      getApplyStatus()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  // 返回
  const back = () => {
    if (isSubimtWaiting !== 0) {
      return
    }
    if (isFirstOut) {
      setFormVisible(true)
      setFormType('popup')
      return
    }
    removeToken()
    navigate(-1)
  }

  // 真实姓名
  const [realName, setRealName] = useState<string>('')
  // 身份证号
  const [identity, setIdentity] = useState<string>('')
  // 年龄
  const [age, setAge] = useState<number | string>()
  // 性别
  const [gender, setGender] = useState<string>('')

  // 城市
  const [formVisible, setFormVisible] = useState<boolean>(false)
  const [cityValue, setCityValue] = useState<(string|null)[]>([])
  const [cityShow, setCityShow] = useState<string>('')
  const [haveCityData, setHaveCityData] = useState<boolean>(false)
  // 设置城市
  const cityConfirm = (value: PickerValue[], extend: PickerValueExtend) => {
    if (value.length) {
      const cityName = extend.items.map(item => item?.label).join()
      setCityShow(cityName)
      const v = value[0]
      if (v === null) {
        return false
      }
      setCityValue(value)
      setHaveCityData(true)
    }
  }

  // 当用户切换已选择金额的时候，进行可借款额度的重新计算
  const againChooseGetNum = (obj: OptionType, currentItem: FormItem, applyLimit: string) => {
    currentItem.options.forEach(item => {
      if (item.value === applyLimit) {
        setEstimatedAmount(estimatedAmount + (obj.num ?? 0) - (item.num ?? 0))
      }
    })
  }

  // 申请额度
  const [applyLimit, setApplyCredit] = useState('')
  const applyCreditOnChange:SetFormFunc = (obj, currentItem) => {
    // 如果没有选择过额度，则直接加上额度
    setLastTimeAmount(estimatedAmount)
    if (!applyLimit) {
      setEstimatedAmount(estimatedAmount + (obj.num ?? 0))
    } else {
      againChooseGetNum(obj, currentItem, applyLimit)
    }
    setApplyCredit(obj.value)
    if (obj.value) {
      if (step < 2) {
        setStep(2)
      }
    }
  }

  // 职业类型
  const [job, setJob] = useState('')
  const applyJobChange:SetFormFunc = (obj, currentItem) => {
    setLastTimeAmount(estimatedAmount)
    if (!job) {
      setEstimatedAmount(estimatedAmount + (obj.num ?? 0))
    } else {
      againChooseGetNum(obj, currentItem, job)
    }
    setJob(obj.value)
    if (obj.value) {
      if (step < 3) {
        setStep(3)
      }
    }
  }


  // 社保
  const [socialSecurity, setSocialSecurity] = useState('')
  const socialSecurityOnChange:SetFormFunc = (obj, currentItem) => {
    setLastTimeAmount(estimatedAmount)
    if (!socialSecurity) {
      setEstimatedAmount(estimatedAmount + (obj.num ?? 0))
    } else {
      againChooseGetNum(obj, currentItem, socialSecurity)
    }
    setSocialSecurity(obj.value)
    if (obj.value) {
      if (step < 4) {
        setStep(4)
      }
    }
  }

  // 公积金
  const [housingFund, setAccumulationFund] = useState('')
  const accumulationFundOnChange:SetFormFunc = (obj, currentItem) => {
    setLastTimeAmount(estimatedAmount)
    if (!housingFund) {
      setEstimatedAmount(estimatedAmount + (obj.num ?? 0))
    } else {
      againChooseGetNum(obj, currentItem, housingFund)
    }
    setAccumulationFund(obj.value)
    if (obj.value) {
      if (step < 5) {
        setStep(5)
      }
    }
  }

  // 保险保单
  const [insurance, setInsurancePolicy] = useState('')
  const insurancePolicyOnChange:SetFormFunc = (obj, currentItem) => {
    setLastTimeAmount(estimatedAmount)
    if (!insurance) {
      setEstimatedAmount(estimatedAmount + (obj.num ?? 0))
    } else {
      againChooseGetNum(obj, currentItem, insurance)
    }
    setInsurancePolicy(obj.value)
    if (obj.value) {
      if (step < 6) {
        setStep(6)
      }
    }
  }

  // 车产类型
  const [carProperty, setCarProduction] = useState('')
  const carProductionOnChange:SetFormFunc = (obj, currentItem) => {
    setLastTimeAmount(estimatedAmount)
    if (!carProperty) {
      setEstimatedAmount(estimatedAmount + (obj.num ?? 0))
    } else {
      againChooseGetNum(obj, currentItem, carProperty)
    }
    setCarProduction(obj.value)
    if (obj.value) {
      if (step < 7) {
        setStep(7)
      }
    }
  }

  // 房产类型
  const [houseProperty, setRealEstate] = useState('')
  const realEstateOnChange:SetFormFunc = (obj, currentItem) => {
    setLastTimeAmount(estimatedAmount)
    if (!houseProperty) {
      setEstimatedAmount(estimatedAmount + (obj.num ?? 0))
    } else {
      againChooseGetNum(obj, currentItem, houseProperty)
    }
    setRealEstate(obj.value)
    if (obj.value) {
      if (step < 8) {
        setStep(8)
      }
    }
  }

  // 芝麻分
  const [sesameCredit, setCreditSesame] = useState('')
  const creditSesameOnChange:SetFormFunc = (obj, currentItem) => {
    setLastTimeAmount(estimatedAmount)
    if (!sesameCredit) {
      setEstimatedAmount(estimatedAmount + (obj.num ?? 0))
    } else {
      againChooseGetNum(obj, currentItem, sesameCredit)
    }
    setCreditSesame(obj.value)
    if (obj.value) {
      if (step < 9) {
        setStep(9)
      }
    }
  }

  // 信用情况
  const [creditInvestigation, setCreditConditions] = useState('')
  const creditConditionsOnChange:SetFormFunc = (obj, currentItem) => {
    setLastTimeAmount(estimatedAmount)
    if (!creditInvestigation) {
      setEstimatedAmount(estimatedAmount + (obj.num ?? 0))
    } else {
      againChooseGetNum(obj, currentItem, creditInvestigation)
    }
    setCreditConditions(obj.value)
    if (obj.value) {
      if (step < 10) {
        setStep(10)
      }
    }
  }

  // 工资发放方式
  const [payoffForm, setPayoffForm] = useState('')
  const applypayoffFormOnChange:SetFormFunc = (obj, currentItem) => {
    setLastTimeAmount(estimatedAmount)
    if (!payoffForm) {
      setEstimatedAmount(estimatedAmount + (obj.num ?? 0))
    } else {
      againChooseGetNum(obj, currentItem, payoffForm)
    }
    setPayoffForm(obj.value)
    if (obj.value) {
      if (step < 11) {
        setStep(11)
      }
    }
  }

  // 向下滚动
  const scrollDown:(distance?:number) => void = (distance = 250) => {
    const dom = document.getElementById('app')
    if (dom) {
      const currentScroll = dom.scrollTop || 0 // 已经被卷掉的高度
      setTimeout(() => {
        dom.scrollTo(0, currentScroll + distance)
      }, 0)
    }
  }

  // popup层向下滚动
  const scrollToBottom:() => void = () => {
    if (popupContentRef.current) {
      popupContentRef.current.scrollTop = popupContentRef.current.scrollHeight
    }
  }

  // 滚动到顶部
  const handleScrollTop:() => void = () => {
    const dom = document.getElementById('app')
    if (dom) {
      dom.scrollTo(0, 0)
    }
  }

  // 回填数据
  const fillData = (info: any) => {
    let totalStep = 0
    let totalNum = 0
    // 回显除了最后一步的其它表单数据
    let hasLocationSwiperIndex = false
    const tempFormItemList = formItemList.map((item, index) => {
      if (item.key !== 'lastPage') {
        if (info[item.key] === null && !hasLocationSwiperIndex) {
          hasLocationSwiperIndex = true
          // formSwiper.current?.swipeTo(index)
        } else {
          item.value = info[item.key] !== null ? info[item.key].toString() : null
          if (info[item.key] !== null) {
            totalStep += 1
            let num = item.options.find(option => option.value === item.value)?.num ?? 0
            totalNum += num
          }
        }
      }
      return item
    })
    // 当前操作步数
    setStep(step + totalStep)
    // 当前可借贷金额
    setEstimatedAmount(estimatedAmount + totalNum)
    setFormItemList([...tempFormItemList])

    // 工作城市
    if (info.workCityCode || info.workCity) {
      setHaveCityData(true)
      setCityValue(info.workCityCode.split(','))
      setCityShow(info.workCity)
    }

    // 申请额度
    if (info.applyLimit !== null) {
      setApplyCredit(info.applyLimit.toString())
    }

    // 真实姓名
    if (info.realName !== null) {
      setRealName(info.realName)
    }
    // 身份证号
    if (info.identity !== null) {
      setIdentity(info.identity)
    }
    // 年龄
    if (info.identity !== null) {
      setAge(info.identity)
    }

    //有房产
    if (info.houseProperty !== null) {
      setRealEstate(info.houseProperty.toString())
    }
    // 车产
    if (info.carProperty !== null) {
      setCarProduction(info.carProperty.toString())
    }
    //有社保
    if (info.socialSecurity !== null) {
      setSocialSecurity(info.socialSecurity.toString())
    }
    // 有公积金
    if (info.housingFund !== null) {
      setAccumulationFund(info.housingFund.toString())
    }
    // 保险保单
    if (info.insurance !== null) {
      setInsurancePolicy(info.insurance.toString())
    }

    // 性别
    if (info.gender !== null) {
      setGender(info.gender)
    }

    // 职业类型
    if (info.job !== null) {
      setJob(info.job.toString())
    }

    // 工资发放方式
    if (info.payoffForm !== null) {
      setPayoffForm(info.payoffForm.toString())
    }
    // 芝麻分
    if (info.sesameCredit !== null) {
      setCreditSesame(info.sesameCredit.toString())
    }
    // 信用情况
    if (info.creditInvestigation !== null) {
      setCreditConditions(info.creditInvestigation.toString())
    }
  }

  const getFillData: () => MatchParams = () => {
    return {
      realName, // 真实姓名
      identity, // 身份证号
      workCityCode: cityValue.join(), // 城市
      workCity: cityShow, // 城市
      sesameCredit, // 芝麻分
      job, // 职业类型
      payoffForm, // 工资发放方式
      applyLimit, // 申请额度
      houseProperty, //房产
      carProperty, //车产
      socialSecurity, //社保
      housingFund, //公积金
      insurance, //保险保单
      creditInvestigation //信用情况
    }
  }

  // 保存上传
  const save = async(params:MatchParams) => {
    try {
      localStorage.setItem('fillInformation', JSON.stringify(params))
      await indexApi.tempSave(params)
    } catch (err) {
      console.log(err)
    }
  }

  // 对表单保存进行截流
  const debounceSave = useMemo(() => debounce(save, 2000, false), [])

  const handleLoginSuccess = (res:any) => {
    Storage.set(TOKEN, res.token)
    setToken(res.token)
  }

  // 将身份证输入单独拎出来监控
  useEffect(() => {
    if (identity) {
      if (validateIDNumber(identity)) {
        debounceSave(getFillData())
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identity])

  // 表单各项变化监控(身份证除外)
  useEffect(() => {
    if (realName || age || gender || cityShow || sesameCredit || creditInvestigation || applyLimit || houseProperty || carProperty || socialSecurity
      || housingFund || insurance || job
      // workDuration || monthlyProfit ||
      || payoffForm) {
      debounceSave(getFillData())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    realName,
    age,
    gender,
    cityShow,
    sesameCredit,
    creditInvestigation,
    applyLimit,
    houseProperty,
    carProperty,
    socialSecurity,
    housingFund,
    insurance,
    job,
    payoffForm
  ])
  // 设置匹配进度
  useEffect(() => {
    let interval:any
    if (isSubimtWaiting === 1) {
      setWaitingProgress(0)
      interval = setInterval(() => {
        setWaitingProgress(prevProgress => {
          if (prevProgress >= 99) {
            clearInterval(interval)
            return 99
          }
          return prevProgress + 1
        })
      }, 50) // 每50毫秒更新一次进度
    }
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [isSubimtWaiting])

  // 开启匹配
  const openMating = () => {
    if (step < 11) {
      return Toast.show('请完善资产信息')
    }
    if (isSubimtWaiting === 0) {
      UserInfoFormRef.current && UserInfoFormRef.current.btnUpdate()
    }
  }

  // 提交信息匹配校验规则
  const validations = [
    {
      condition: !realName,
      message: '请输入真实姓名',
      action: handleScrollTop
    },
    {
      condition: !/^[\u4e00-\u9fa5]+$/.test(realName),
      message: '真实姓名不能含有非中文字符',
      action: handleScrollTop
    },
    {
      condition: !applyLimit,
      message: '请选择申请额度',
      action: handleScrollTop
    },
    {
      condition: !job,
      message: '请选择职业类型',
      action: handleScrollTop
    },
    {
      condition: !socialSecurity,
      message: '请选择社保',
      action: handleScrollTop
    },
    {
      condition: !housingFund,
      message: '请选择公积金',
      action: handleScrollTop
    },
    {
      condition: !insurance,
      message: '请选择保险保单信息',
      action: handleScrollTop
    },
    {
      condition: !carProperty,
      message: '请选择车产',
      action: handleScrollTop
    },
    {
      condition: !houseProperty,
      message: '请选择房产',
      action: handleScrollTop
    },
    {
      condition: !sesameCredit,
      message: '请选择芝麻分',
      action: handleScrollTop
    },
    {
      condition: !creditInvestigation,
      message: '请选择信用情况',
      action: handleScrollTop
    },
    {
      condition: !payoffForm,
      message: '请选择工资发放方式',
      action: handleScrollTop
    },
  ]

  // 匹配成功提交，开启提交
  const confirm = async() => {
    if (isFirstSubmit) {
      indexApi.reportUserBehavior(2)
      setIsFirstSubmit(false)
    }
    for (const { condition, message, action } of validations) {
      // 判断condition是否符合
      if (condition) {
        if (action) {
          action()
        }
        return Toast.show(message)
      }
    }
    const params:SubmitParams = {
      realName, // 真实姓名
      workCityCode: cityValue.join(), // 城市
      workCity: cityShow, // 城市
      job, // 职业类型
      payoffForm, // 工资发放方式
      applyLimit, // 申请额度
      adChannelCode,
      isHuaWeiPhone,
      sesameCredit, //芝麻分
      houseProperty, //房产
      carProperty, //车产
      socialSecurity, //社保
      housingFund, //公积金
      insurance, //保险保单
      creditInvestigation //信用情况
    }
    params.identity = identity //身份证号
    if (validateIDNumber(identity)) {
      params.gender = getSex(identity) // 性别
      params.age = getAge(identity) // 年龄
    }
    localStorage.setItem('fillInformation', JSON.stringify(params))
    try {
      // 姓名、身份证二要素验证
      await indexApi.identityNameVerify({
        realName,
        identity,
        adChannelCode
      })
      setIsSubimtWaiting(1)
      // setSubmitOption(params)
      subimtFormToMatching(params)
    } catch (error) {
      Toast.show('姓名与身份证匹配失败')
    }
  }

  // 开启匹配
  const subimtFormToMatching = async(params: SubmitParams) => {
    try {
      let res: any = await indexApi.saveCustomerInfo(params)
      // 神策埋点上报 - 用户点击提交表单
      sensors.track(SensorsType.click_submit_form_info, {
        ad_channel_code: adChannelCode || search.get('adChannelCode') || localStorage.getItem('ad_channel_code'),
      })
      if (res && res.formProcessStatus === 0 && res.productName) {
        // 开授权页，现在是弹窗形式
        let tempOptions = {
          adChannelCode: params.adChannelCode,
          isHuaWeiPhone: params.isHuaWeiPhone,
          applyLimit: params.applyLimit,
          workCity: params.workCity,
          rqResult: res
        }
        // 报错表单提交结果
        // matchingSuccess()
        setIsSubimtWaiting(2)
        setTimeout(() => {
          onSuccessSubmit(tempOptions)
        }, 1000)
      } else {
        const query:any = res && res.productName ? res : { rePageShowType: params.adChannelCode === '9o0w2B' && params.isHuaWeiPhone ? 2 : 0 }
        navigate(`/fill-information/success?options=${encodeURIComponent(JSON.stringify(query))}`)
      }
    } catch (error) {
      console.log(error)
    }
  }


  // 表单提交成功，开启授权弹窗
  const onSuccessSubmit = (result: any) => {
    setIsSubimtWaiting(3)
    setIsOpenAuthorizePopup(true)
    setmatchingResult(result)
  }


  const onChangeFormItem = (obj: OptionType, currentItem: any, nextIndex: number, type?:'string') => {
    switch (currentItem.key) {
      case 'applyLimit':
        applyCreditOnChange(obj, currentItem)
        break
      case 'job':
        applyJobChange(obj, currentItem)
        break
      case 'socialSecurity':
        socialSecurityOnChange(obj, currentItem)
        break
      case 'housingFund':
        accumulationFundOnChange(obj, currentItem)
        break
      case 'insurance':
        insurancePolicyOnChange(obj, currentItem)
        break
      case 'carProperty':
        carProductionOnChange(obj, currentItem)
        break
      case 'houseProperty':
        realEstateOnChange(obj, currentItem)
        break
      case 'sesameCredit':
        creditSesameOnChange(obj, currentItem)
        break
      case 'creditInvestigation':
        creditConditionsOnChange(obj, currentItem)
        break
      case 'payoffForm':
        applypayoffFormOnChange(obj, currentItem)
        break
      default:
        // 默认情况下不执行任何操作
        break
    }
    scrollDown()
    currentItem.value = obj.value
    formItemList[nextIndex] = currentItem
    setFormItemList(formItemList)
  }

  // form表单改变时，修改姓名年龄和城市
  const onChangeFormValue = (type: string, val: any) => {
    if (type === 'realName') {
      setRealName(val)
    } else if (type === 'identity') {
      setIdentity(val)
    }
  }

  // 单页表单项列表
  const [formItemList, setFormItemList] = useState([...formItems])

  // 设置第二屏步骤
  useEffect(() => {
    if (step) {
      setProgressPercentage((step - 1) * 10)
      if (step === 11) {
        // if (!haveCityData) {
        openLocation()
        // }
      }
      if (popupContentRef.current) {
        scrollToBottom()
      }
      if (step - 1 > formItemList.length) {
        setStep(formItemList.length)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step])

  // 获取定位
  const openLocation = () => {
    let hasHandleCopyFunction = false
    // 开始执行位置授权
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        position => {
          // 获取到的地理位置 position
          if (hasHandleCopyFunction) {
            return
          }
          getUserLocation(position.coords.latitude, position.coords.longitude)
        },
        error => {
          if (hasHandleCopyFunction) {
            return
          }
          getUserLocation(null, null)
        },
        {
          enableHighAccuracy: true, // 位置是否精确获取
          timeout: 2000, //获取位置允许的最长时间
        }
      )
    } else {
      getUserLocation(null, null)
    }
  }

  const getUserLocation = async(latitude:any, longitude:any) => {
    let params = latitude ? {
      location: `${latitude},${longitude}`,
      adChannelCode,
      coordtype: 'wgs84ll'
    } : {
      adChannelCode,
    }
    let res: any = await activityApi.reverseGeo(params)
    // res数据格式 例：{ province: 'xx', city: 'xx' }
    let currentProvince:any = cityDate.find(item => {
      return item.label === res.data.province
    })
    let currentCity:any = ''
    if (currentProvince && currentProvince.children) {
      currentCity = currentProvince.children.find((cityItem:any) => {
        return cityItem.label === res.data.city
      })
    }
    // 筛选出来的城市 currentProvince, currentCity
    if (currentProvince && currentCity && !haveCityData) {
      const workCityCode = `${currentProvince.value},${currentCity.value}`
      const workCity = `${currentProvince.label},${currentCity.label}`
      setCityValue(workCityCode.split(','))
      setCityShow(workCity)
      setHaveCityData(true)
    }
  }

  useEffect(() => {
    const urlToken = search.get('token')
    // 如果链接存在token证明是联登，上报访问表单填写页
    // 前端神策埋点 - 用户访问表单页
    sensors.track(SensorsType.visit_form_page, {
      ad_channel_code: adChannelCode || search.get('adChannelCode') || localStorage.getItem('ad_channel_code'),
      mobile: userInfo?.mobile || localStorage.getItem('user_phone'),
      h5_federated_login: !!urlToken,
    })
    localStorage.removeItem('productBack')
  }, [])

  return (
    <div className={styles.fillInformation} style={{ paddingTop: visibleHead ? 'auto' : '0px' }}>
      {
        visibleHead ? (<Header backCallBack={back} hiddenHeaderBottomLine={true} headerColor={'#FFEAEC'} />) : (<></>)
      }
      <div className={styles.headerBox}>
        <div className={styles.headerIntroduce}>根据您的基础信息进行评告，请如实填写</div>
        <div className={styles.limitBox}>
          <CountUp className={styles.headerLimit} start={lastTimeAmount} end={ estimatedAmount }/>
        </div>
        <span className={styles.headerText}>
            *最终额度以最后审批为准
        </span>
        <div className={styles.progress}>
          <div className={styles.progressBar}>
            <div className={styles.progressLine} style={{ transform: `translate(${-100 + progressPercentage}%)` }}></div>
          </div>
          <div className={styles.progressPercentage}>{progressPercentage}%</div>
        </div>
      </div>
      <div className={styles.userInfoForm}>
        <UserInfoFormComponent
          onChange={onChangeFormItem}
          onChangeFormValue={onChangeFormValue}
          step={step}
          formItemList={formItemList}
          realName={realName}
          identity={identity}
          cityShow={cityShow}
          cityValue={cityValue}
          cityConfirm={cityConfirm}
          confirm={confirm}
          formType={formType}
          isSubimtWaiting={isSubimtWaiting}
          waitingProgress={waitingProgress}
        ></UserInfoFormComponent>
      </div>
      <Popup
        visible={formVisible}
        // onMaskClick={() => {
        //   setFormVisible(false)
        //   setFormType('form')
        // }}
        bodyStyle={{
          height: '60vh',
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px',
        }}
        maskStyle={{
          opacity: 0
        }}
      >
        <div className={styles.popupBox}>
          <div className={styles.popupTitle}>
            <img src={require('@imgs/form-result/success-fill.png')} className={styles.successIcon} alt="youqianqianbao-success" />
            <h1>您有一笔授信{ parseNum(estimatedAmount)}的额度</h1>
          </div>
          <div ref={popupContentRef} className={styles.popupContent}>
            {
              formVisible && <UserInfoFormComponent
                ref={UserInfoFormRef}
                onChange={onChangeFormItem}
                onChangeFormValue={onChangeFormValue}
                step={step}
                formItemList={formItemList}
                realName={realName}
                identity={identity}
                cityShow={cityShow}
                cityValue={cityValue}
                cityConfirm={cityConfirm}
                confirm={confirm}
                formType={formType}
                isSubimtWaiting={isSubimtWaiting}
                waitingProgress={waitingProgress}
              ></UserInfoFormComponent>
            }
          </div>
          <div className={styles.popupBtnList}>
            <Button
              onClick={() => {
                setFormVisible(false)
                setFormType('form')
                setisFirstOut(false)
              }}
              className={styles.btnCancle}
              shape="rounded"
              size="large"
            >确认放弃
            </Button>
            <Button
              onClick={openMating}
              className={styles.btnOk}
              shape="rounded"
              size="large"
              // eslint-disable-next-line no-nested-ternary
            >{isSubimtWaiting === 0 ? '继续申请' : isSubimtWaiting === 1 ? `信息认证中...${waitingProgress}%` : isSubimtWaiting === 2 ? '信息认证中...100%' : '信息认证成功' }
            </Button>
          </div>
        </div>
      </Popup>

      {/* 提交表单等待加载 */}
      {/* {isSubimtWaiting !== 0 && <SubmitWaitingPopup option={submitOption!} sourceType="submit" onSuccessSubmit={onSuccessSubmit} matchingSuccess={matchingSuccess}></SubmitWaitingPopup>} */}

      {/* 表单匹配弹 */}
      {
        isOpenAuthorizePopup && <Popup visible={isOpenAuthorizePopup} bodyStyle={{
          height: '75vh',
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px',
        }}>
          <div className={styles.AuthorizeCommonPopup}>
            {/* <img src={require('@/assets/imgs/form-result/authorize-success.png')} className={styles.successBg} alt="youqianqianbao-authorize-success" /> */}
            <div className={styles.overlay}>
              {/* <h1>恭喜您，贷款匹配成功</h1>
              <div className={styles.seconedTitle}>
                <img src={require('@/assets/imgs/form-result/new-success.png')} className={styles.iconSuccess} alt="youqianqianbao-new-success" />
                <span>严选正规机构100+，为您匹配低息借款方案</span>
              </div> */}
              <div className={styles.content}>
                <AuthorizeCommonPopup matchingResult={matchingResult} ></AuthorizeCommonPopup>
              </div>
            </div>
          </div>
        </Popup>
      }
    </div>
  )
}

export default FillInformation